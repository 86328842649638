import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../shared/Container';

const AlternateFooter = () => {
  return (
    <section className="w-full absolute bottom-0 left-0">
      <footer className="text-black sm:py-6 py-4">
        <Container>
          <div className="flex flex-col lg:flex-row justify-between items-center gap-2 text-gray-400 text-sm sm:text-base">
            <p className="footerrights">
              © 2023 Zedintro. All rights reserved.
            </p>

            <div className="flex pb-2 lg:py-0 footerinfo">
              <Link to="/terms">Terms</Link>
              <Link to="/privacy" className="px-4 lg:px-8">
                Privacy
              </Link>
              <Link to="">Cookies</Link>
            </div>
          </div>
        </Container>
      </footer>
    </section>
  );
};

export default AlternateFooter;
