import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../components/layout/navbar';
import AlternateFooter from '../components/layout/AlternateFooter';
import { FiChevronDown, FiSearch } from 'react-icons/fi';
import { createSearchParams, useLocation } from 'react-router-dom';
import {
  getProfessionals,
  setGiftRecipient,
  setRateInUserCurrency,
} from '../features/user/userSlice';
import ReactPaginate from 'react-paginate';
import { Dialog, Transition } from '@headlessui/react';
import giftSvg from '../assets/icons/giftSvg.svg';
import { useNavigate } from 'react-router-dom';
import SelectMenu from '../components/shared/SelectMenu';
import {
  orderOption,
  priceFromOption,
  priceToOption,
  searchOption,
} from '../data/select';
import { BiChevronDown } from 'react-icons/bi';
import FacebookPixel from '../utils/FacebookPixel';
import Container from '../components/shared/Container';
import ExpertLoadingCard from '../components/expert/ExpertLoadingCard';
import ExpertCard from '../components/expert/ExpertCard';

const Search = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const searchValue = param.get('search');

  const { searchParams, expertsData, getStatus, currency } =
    useSelector((state) => state.user);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [profile, setProfile] = useState(null);
  const [giftRecipientData, setGiftRecipientData] = useState({});
  const [filterFields, setFilterFields] = useState({
    skill: '',
    priceFrom: '',
    priceTo: '',
    limit: '',
    orderBy: '',
    searchField: searchValue ? searchValue : 'name',
  });

  useEffect(() => {
    dispatch(setRateInUserCurrency(currency));
  }, []);

  useEffect(() => {
    if (searchParams !== '') {
      dispatch(
        getProfessionals({
          name: searchParams,
          industry: searchParams,
          skip: skip,
          searchField: filterFields.searchField,
        }),
      );
      setSearchText(searchParams);
    }
  }, [skip]);

  const handleSearch = async (e) => {
    e.preventDefault();

    if (searchText.trim()) {
      dispatch(
        getProfessionals({
          name: searchText,
          industry: '',
          skip: skip,
          searchField: filterFields.searchField,
        }),
      );
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const handlePageClick = (e) => {
    setSkip(e.selected);
  };

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const handleGiftChange = (e) => {
    const { name, value } = e.target;
    setGiftRecipientData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSetGiftRecipient = (e, expertId) => {
    e.preventDefault();
    // sessionStorage.setItem('giftInfo', JSON.stringify(giftState));
    dispatch(setGiftRecipient({ ...giftRecipientData }));
    navigate({
      pathname: '/gift/' + expertId,
      search: createSearchParams({
        email: giftRecipientData.email,
        name: giftRecipientData.name,
      }).toString(),
    });
  };

  const giftModal = (expert) => {
    setProfile(expert);
    setOpen(true);
  };

  const handleFilterFields = (item, field) => {
    setFilterFields({ ...filterFields, [field]: item.option });
  };

  return (
    <div className="searchresults mx-auto altNav min-h-[100vh] relative">
      <FacebookPixel />
      <Navbar />

      <section className="pt-20">
        <Container>
          <form className="" onSubmit={handleSearch}>
            <div className="sm:rounded-xl rounded-lg max-w-5xl w-full m-auto flex bg-white shadow-md relative">
              <div className="bg-[#EDDBFF]/50 sm:rounded-l-xl rounded-l-lg flex items-center">
                <SelectMenu
                  options={searchOption}
                  className={'top-12 min-w-[10rem]'}
                  optionField="searchField"
                  handleSelect={handleFilterFields}
                >
                  <div className="space-x-2 px-4 text-sm flex justify-center items-center text-bgPurple">
                    <p>
                      {filterFields.searchField === 'skill' ? 'Skill' : 'Name'}
                    </p>
                    <BiChevronDown className="text-xl" />
                  </div>
                </SelectMenu>
              </div>

              <input
                type="text"
                name="industry"
                placeholder={
                  searchOption === 'skill'
                    ? 'example: marketing, architecture'
                    : 'example: Johnson, Anna'
                }
                className="pl-4 bg-white py-2 sm:py-3 lg:py-4 grow outline-none border-none placeholder:text-sm sm:rounded-r-xl rounded-r-lg"
                value={searchText}
                onChange={handleChange}
              />

              <button className="flex items-center justify-center pr-5 text-right sm:rounded-r-xl rounded-r-lg absolute right-0 h-full">
                <FiSearch className="text-gray-500 lg:text-xl sm:text-lg text-base" />
              </button>
            </div>

            <div className="mx-auto mt-4 flex flex-wrap space-x-2 sm:justify-center">
              <SelectMenu
                options={priceFromOption}
                className={'w-16 top-14 right-6'}
                optionField="priceFrom"
                handleSelect={handleFilterFields}
              >
                <div className="bg-white border flex gap-4 justify-between items-center border-gray-200 min-w-[6.5rem] text-left font-normal lg:mx-3 text-xs sm:text-sm lg:text-base py-2 px-2 lg:px-3 rounded-md text-gray-500 shadow">
                  <p>
                    {filterFields.priceFrom
                      ? filterFields.priceFrom
                      : 'Price From'}
                  </p>
                  <FiChevronDown />
                </div>
              </SelectMenu>

              <SelectMenu
                options={priceToOption}
                className={'w-16 top-14 right-6'}
                handleSelect={handleFilterFields}
                optionField="priceTo"
              >
                <div className="bg-white border flex justify-between items-center border-gray-200 min-w-[6.5rem] text-left font-normal lg:mx-3 text-xs sm:text-sm lg:text-base py-2 px-2 lg:px-3 rounded-md text-gray-500 shadow">
                  <p>
                    {filterFields.priceTo ? filterFields.priceTo : 'Price To'}
                  </p>
                  <FiChevronDown />
                </div>
              </SelectMenu>

              <SelectMenu
                options={orderOption}
                className={'w-32 top-14 right-6'}
                handleSelect={handleFilterFields}
                optionField="orderBy"
              >
                <div className="bg-white border flex justify-between items-center border-gray-200 min-w-[6.5rem] text-left font-normal lg:mx-3 text-xs sm:text-sm lg:text-base py-2 px-2 lg:px-3 rounded-md text-gray-500 shadow">
                  <p>
                    {filterFields.orderBy ? filterFields.orderBy : 'Order By'}
                  </p>
                  <FiChevronDown />
                </div>
              </SelectMenu>
            </div>
          </form>
        </Container>
      </section>

      <section className="sm:py-20 py-8 pb-24">
        <Container>
          <div className="flex pt-10 pb-8">
            {expertsData?.docs && (
              <p className="mr-auto">
                {expertsData?.docs?.length} experts found
              </p>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mx-auto w-full">
            {getStatus === 'loading' ? (
              <>
                <ExpertLoadingCard />

                <ExpertLoadingCard />
              </>
            ) : (
              expertsData &&
              expertsData?.docs?.map((expert) => (
                <ExpertCard expert={expert} handleGiftModal={() => giftModal(expert)} key={expert.id} />
              ))
            )}

            <Transition.Root show={open} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="bg-white p-4 sm:p-7">
                          <div className="text-center sm:text-left">
                            <img
                              src={giftSvg}
                              alt="gift-icon"
                              className="mx-auto"
                            />
                            <hr className="my-10 border border-solid border-[#AEAEAE]" />
                            <div className="mt-2">
                              <section className="card !bg-[#E8E0EB] p-8 rounded-2xl mt-12 lg:mt-0">
                                <p className="font-semibold text-xl">
                                  Gift a call with{' '}
                                  {profile?.firstName +
                                    ' ' +
                                    profile?.lastName}{' '}
                                </p>
                                <p className="text-sm pt-1">
                                  {`Please fill in the recipient's details below`}
                                </p>
                                <form className="grid mt-5 gap-4">
                                  <input
                                    type="text"
                                    placeholder="Full name"
                                    className="border border-neutral-300 rounded-lg w-full py-2 px-3"
                                    onChange={handleGiftChange}
                                    name="name"
                                    required
                                  />
                                  <div className="flex gap-expert-4 gap-2">
                                    <input
                                      type="email"
                                      placeholder="Email address"
                                      className="border border-neutral-300 rounded-lg w-full py-2 px-3"
                                      onChange={handleGiftChange}
                                      name="email"
                                      required
                                    />
                                    <button
                                      className="flex-none rounded-lg bg-[#270058] text-white px-8 text-sm disabled:bg-[#280058af]"
                                      disabled={
                                        Boolean(giftRecipientData.email) ==
                                        false ||
                                        Boolean(giftRecipientData.name) ==
                                        false
                                      }
                                      onClick={(e) =>
                                        handleSetGiftRecipient(e, profile.id)
                                      }
                                      type="button"
                                    >
                                      Gift call
                                    </button>
                                  </div>
                                </form>
                              </section>
                            </div>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </div>

          <ReactPaginate
            breakLabel="..."
            nextLabel=""
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={expertsData.totalPages}
            previousLabel=""
            renderOnZeroPageCount={null}
            activeClassName="w-min rounded-xl bg-[#8338EC] text-white py-2 px-4 font-bold"
            className="flex gap-expert-4 w-min mx-auto mt-10"
          />
        </Container>
      </section>

      <AlternateFooter />
    </div>
  );
};

export default Search;
