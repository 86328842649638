import React from 'react';
import Layout from '../../components/layout/layout';
import Header from '../../components/banner';
import { BsCalendar4, BsClock } from 'react-icons/bs';
import user from '../../assets/imgs/user.png';
import { useState } from 'react';
import success from '../../assets/icons/success.svg';
import { useNavigate } from 'react-router';
import ProtectedRoute from '../../utils/ProtectedRoute';
import Container from '../../components/shared/Container';

const CallsNotifications = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);
  const [approveCallModal, setApproveCallModal] = useState(false);
  //   const [closeModal, setCloseModal] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleModalTwo = () => {
    setShowModalTwo(!showModalTwo);
  };

  const toggleApproveCall = () => {
    setApproveCallModal(!approveCallModal);
  };

  const toggleDone = () => {
    setShowModal(false);
    setShowModalTwo(false);
    setApproveCallModal(false);
  };

  return (
    <ProtectedRoute>
      <div className="altNav">
        <Layout>
          <Header title="Calls" pathUrl="/" />
          {/* grid mx-10 lg:mx-20 gap-y-6 */}
          <Container>
            <div className="rounded-2xl  shadow-sm card">
              <div>
                <div className="flex mb-3 ">
                  <p className="text-[#BA8800]  requested pt-1 pl-6 mt-2 rounded-2xl">
                    {'Requested'}
                  </p>
                  <p className=" text-[13px]  lg:text-[20px] font-semibold ml-4 mt-1 mb-1">
                    Startup brainstorming call
                  </p>
                </div>
                <div className="flex gap-y-4 ml-2 flex-col">
                  <div className="flex">
                    <BsCalendar4
                      className="text-neutral-500 mr-1 self-center"
                      size={18}
                    />
                    {'19, September 2022'}
                  </div>
                  <div className="flex">
                    <BsClock
                      className="text-neutral-500 mr-1 self-center"
                      size={18}
                    />
                    {'11:00 - 11:45'}
                  </div>
                </div>
              </div>

              <div className="flex gap-x-4 items-center mt-3 lg:mt-6 py-4 lg:py-0">
                <img
                  src={user}
                  className="rounded-full h-14 w-14"
                  alt="user-image"
                />
                <div>
                  <p>{'Emeka Obi'}</p>
                  <p className="text-neutral-500">{'Co-founder at Robopay'}</p>
                </div>
              </div>

              <hr className="w-full bg-slate-400 mt-5 lg:mt-10 " />
              {/* <div className="mt-10 flex place-content-end invisible">
            <hr className="w-full bg-slate-400 " />

            {/* <button className='w-full bg-[#270058] text-white rounded p-2 mt-6' type='submit'>Approve call</button> */}
              {/* <button className='w-full bg-[#270058] text-white rounded p-2 mt-6' onClick={toggleModal}>Decline call</button> */}
              {/* </div> */}
              <div className="flex place-content-end">
                {/* <hr className='w-full bg-slate-400 '/> */}

                <button
                  className="lg:px-28  w-full lg:w-3/12  mr-6 bg-[#270058] text-white rounded-lg p-2 mt-6"
                  onClick={toggleApproveCall}
                >
                  Approve call
                </button>
                <button
                  className=" lg:px-28 w-full  lg:w-3/12  text-[#270058] border-2 border-blue-900 rounded-lg   p-2 mt-6"
                  onClick={toggleModal}
                >
                  Decline call
                </button>
              </div>
            </div>
          </Container>

          {/* <button onClick={toggleModal}>Open Modal</button> */}
          {showModal && (
            <div className="modal-overlay">
              <div className="modal-2">
                <h2 className="mt-12 mx-auto max-w-sm text-center font-bold text-[1.75rem]">
                  Are you sure you want to decline the call?
                </h2>
                <div className="mt-10 px-10 flex place-content-end">
                  {/* <hr className='w-full bg-slate-400 '/> */}

                  <button
                    className="w-full mr-10 bg-[#270058] text-white rounded-lg p-2 mt-6"
                    onClick={toggleModalTwo}
                  >
                    Yes
                  </button>
                  <button
                    className="w-full bg-[#EDDBFF]  text-[#270058] border-2 rounded-lg  p-2 mt-6"
                    onClick={toggleModal}
                  >
                    No
                  </button>
                </div>

                <hr className="mt-20 border-1 border-gray-400" />
              </div>
            </div>
          )}

          {showModalTwo && (
            <div className="modal-overlay">
              <div className="modal-2">
                <img
                  src={success}
                  className="mx-auto mt-10"
                  alt="success-icon"
                />
                <h2 className="mt-5 text-center font-bold text-3xl">
                  Your call was successfully declined
                </h2>
                <div className="grid place-items-center">
                  <button
                    onClick={toggleDone}
                    className=" w-2/4  bg-[#270058] text-white rounded-lg p-2 mt-6"
                  >
                    Done
                  </button>
                </div>

                {/* <button onClick={toggleModal}>Close Modal</button> */}
              </div>
            </div>
          )}

          {approveCallModal && (
            <div className="modal-overlay">
              <div className="modal-2">
                <img
                  src={success}
                  className=" mx-auto mt-5 lg:mt-10"
                  alt="success-icon"
                />
                <h2 className="mt-5 text-center font-bold text-3xl">
                  Your call was successfully approved.
                </h2>
                <p className="text-center mt-2">
                  Please add this call to your calendar
                </p>
                <div className=" grid place-items-center">
                  <button
                    onClick={() => navigate('/expert/account/availability')}
                    className=" w-2/4  bg-[#270058] text-white rounded-lg p-2 mt-6"
                  //  onClick={toggleApproveCall}
                  >
                    Add to calender
                  </button>
                </div>

                {/* <button onClick={toggleModal}>Close Modal</button> */}
              </div>
            </div>
          )}
        </Layout>
      </div>
    </ProtectedRoute>
  );
};

export default CallsNotifications;
