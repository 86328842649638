import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AlternateFooter from './AlternateFooter';
import Navbar from './navbar';
import DashNavbar from './dashNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { setDashGuide } from '../../features/app/appSlice';
import { useLocation } from 'react-router';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { dashGuide } = useSelector((state) => state.app);
  const [guideState, setGuideState] = useState(
    localStorage?.getItem('expertGuide'),
  );

  useEffect(() => {
    // Set initial state
    setGuideState(localStorage?.getItem('expertGuide'));
  }, []);

  useEffect(() => {
    if (guideState === 0) {
      dispatch(setDashGuide(1));
    } else {
      dispatch(setDashGuide(parseInt(guideState)));
    }
  }, [guideState]);

  return (
    <div className="bg-[#EFEFEF] min-h-screen flex flex-col relative shadow">
      {window.location.pathname.includes('expert') ? (
        <div className="fixed top-0 left-0 w-full z-50">
          <DashNavbar />
        </div>
      ) : (
        <div className="fixed top-0 left-0 w-full right-0 z-30">
          <Navbar />
        </div>
      )}

      <div className="sm:mt-[3rem] mt-20 sm:pb-32 pb-40 grow">{children}</div>

      {dashGuide && location.pathname === '/expert/dashboard' ? (
        <div className="absolute top-0 bottom-0 w-full h-full z-20 bg-black/70"></div>
      ) : null}

      <AlternateFooter />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
