import React from "react";
import { BsCalendar4, BsClock } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import ReactTimeAgo from "react-time-ago";
import SessionDropdown from "../../components/shared/SessionDropdown";

const SessionCard = ({
    session,
    type = "regular", // "regular" or "gifted"
    onSessionClick,
    getStatus,
    statusShed,
    options,
    duration,
    location,
}) => {
    const isGifted = type === "gifted";

    return (
        <div
            className={`grid lg:grid-cols-3 rounded-2xl shadow-sm ${isGifted ? "card" : "bg-white"
                } p-4 sm:p-10 cursor-pointer`}
            onClick={() =>
                isGifted
                    ? onSessionClick(`/gift-book/${session?.expert?.id}`)
                    : onSessionClick(session)
            }
        >
            <div className="gap-y-4 grid lg:gap-y-0">
                <span
                    className={`${isGifted
                        ? "bg-[#FFEFD2] text-[#BA8800]"
                        : statusShed(session)
                        } sm:py-1.5 py-1 sm:px-4 px-3 text-xs sm:text-sm sm:rounded-2xl rounded-xl w-min`}
                >
                    {isGifted ? "Gifted" : getStatus(session)}
                </span>

                <p className="sm:text-[22px] text-lg sm:mt-2.5 sm:mb-1">
                    {session?.title}
                </p>

                <div className="flex sm:mt-2 justify-between sm:justify-start sm:gap-x-4">
                    <div className="flex items-center text-xs sm:text-sm md:text-base">
                        <BsCalendar4 className="text-neutral-500 mr-1 self-center" size={18} />
                        {isGifted
                            ? session?.createdAt &&
                            new Date(session?.createdAt).toLocaleDateString("en-US", options)
                            : session?.startsAt &&
                            new Date(session?.startsAt).toLocaleDateString("en-US", options)}
                    </div>

                    {!isGifted && (
                        <div className="flex items-center text-xs sm:text-sm md:text-base">
                            <BsClock className="text-neutral-500 mr-1 self-center" size={18} />
                            {session?.startsAt &&
                                new Date(session.startsAt).toLocaleString("en-US", duration)}{" "}
                            -{" "}
                            {session?.endsAt &&
                                new Date(session.endsAt).toLocaleString("en-US", duration)}
                        </div>
                    )}
                </div>
            </div>

            <div className="flex gap-x-4 items-center py-4 lg:py-0">
                {session?.[isGifted ? "expert" : "participant"]?.profilePhoto ? (
                    <div className="sm:w-14 sm:h-14 w-12 h-12 rounded-full object-cover">
                        <img
                            src={
                                session?.[isGifted ? "expert" : "participant"]?.profilePhoto
                            }
                            alt={`${session?.[isGifted ? "expert" : "participant"]?.firstName} ${session?.[isGifted ? "expert" : "participant"]?.lastName
                                }`}
                            className="w-full h-full rounded-full object-cover object-top"
                        />
                    </div>
                ) : (
                    <div className="bg-gray-100 w-14 h-14 rounded-full flex justify-center align-middle items-center">
                        <FiUser className="text-[#270058]" size={28} />
                    </div>
                )}

                <div>
                    <p>
                        {`${session?.[isGifted ? "expert" : "participant"]?.firstName} ${session?.[isGifted ? "expert" : "participant"]?.lastName
                            }`}
                    </p>

                    <p className="text-neutral-500">
                        {session?.[isGifted ? "expert" : "participant"]?.jobTitle}
                    </p>
                </div>
            </div>

            <div className="text-neutral-500 text-sm md:ml-auto md:mr-0 md:-mt-32 lg:mt-0 flex justify-between flex-row-reverse sm:flex-col">
                <div className="inline-flex sm:justify-end relative">
                    {!isGifted && session.status === 'completed' &&
                        !location.pathname.includes('expert') && (
                            <SessionDropdown session={session} />
                        )}
                </div>

                {session?.updatedAt && (
                    <ReactTimeAgo date={session.updatedAt} locale="en-US" />
                )}
            </div>
        </div>
    );
};

export default SessionCard;
