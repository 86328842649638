/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DailyIframe from '@daily-co/daily-js';
import CallInterface from '../components/shared/CallInterface';
import { useDispatch, useSelector } from 'react-redux';
import { resetSession, singleSession } from '../features/session/sessionSlice';

/* We decide what UI to show to users based on the state of the app, which is dependent on the state of the call object. */
const STATE_IDLE = 'STATE_IDLE';
// const STATE_CREATING = 'STATE_CREATING';
// const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_LEAVING = 'STATE_LEAVING';
const STATE_ERROR = 'STATE_ERROR';
const STATE_HAIRCHECK = 'STATE_HAIRCHECK';

const Meeting = () => {
  const [appState, setAppState] = useState(STATE_IDLE);
  const [callObject, setCallObject] = useState(null);
  const dailyFrameRef = useRef(null);
  const { session } = useSelector((state) => state.session);
  // Get the URL from the current window
  const currentUrl = window.location.href;

  const dispatch = useDispatch();

  // Function to extract the value of 't' from a URL
  const extractRoomFromUrl = (url) => {
    // Create a URL object from the provided URL
    const urlObject = new URL(url);
    // Get the value of the 'roomUrl' parameter
    const roomUrl = urlObject.searchParams.get('roomUrl');
    return roomUrl;
  };
  // Function to extract the value of 't' from a URL
  const extractTokenFromUrl = (url) => {
    // Create a URL object from the provided URL
    const urlObject = new URL(url);
    // Get the value of the 'roomUrl' parameter
    const roomUrl = urlObject.searchParams.get('roomUrl');
    // Check if 'roomUrl' exists
    if (!roomUrl) {
      console.error('roomUrl parameter is missing from the URL.');
      return null; // Return null if 'roomUrl' is missing
    }
    // Create a new URL object from the 'roomUrl'
    const roomUrlObject = new URL(roomUrl);
    // Get the value of the 't' parameter from the 'roomUrl'
    const tokenWithParams = roomUrlObject.searchParams.get('t');
    return tokenWithParams;
  };

  // Call the function to extract the token from the current URL
  const token = extractTokenFromUrl(currentUrl);
  // Call the function to extract the room from the current URL
  const room = extractRoomFromUrl(currentUrl);

  const startHairCheck = useCallback(async () => {
    try {
      if (!dailyFrameRef.current) {
        const newCallObject = DailyIframe.createCallObject();
        setCallObject(newCallObject);
        dailyFrameRef.current = newCallObject;
      }
      setAppState(STATE_HAIRCHECK);
      await dailyFrameRef.current.preAuth({
        url: room,
        token: token,
      });
      await dailyFrameRef.current.startCamera();
      await dailyFrameRef.current.join({
        url: room,
        userName: 'agba',
        token: token,
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const callId = localStorage.getItem('callId');

  useEffect(() => {
    dispatch(
      singleSession({
        id: callId,
        admin: location.pathname.includes('expert') ? true : false,
      }),
    );
    return () => {
      dispatch(resetSession());
    };
  }, []);

  // const showHairCheck = !apiError && appState === STATE_HAIRCHECK;

  React.useMemo(() => {
    // const url = roomUrlFromPageUrl();
    if (room) {
      startHairCheck(room);
    }
  }, [startHairCheck, room]);

  /**
   * Start leaving the current call.
   */
  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    // If we're in the error state, we've already "left", so just clean up
    if (appState === STATE_ERROR) {
      callObject.destroy().then(() => {
        setCallObject(null);
        setAppState(STATE_IDLE);
      });
    } else {
      /* This will trigger a `left-meeting` event, which in turn will trigger
      the full clean-up as seen in handleNewMeetingState() below. */
      setAppState(STATE_LEAVING);
      callObject.leave();
      window.history.back();
    }
  }, [callObject, appState]);

  /**
   * Update app state based on reported meeting state changes.
   *
   * NOTE: Here we're showing how to completely clean up a call with destroy().
   * This isn't strictly necessary between join()s, but is good practice when
   * you know you'll be done with the call object for a while, and you're no
   * longer listening to its events.
   */
  React.useEffect(() => {
    if (!callObject) return;

    const events = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    function handleNewMeetingState() {
      switch (callObject.meetingState()) {
        case 'joined-meeting':
          setAppState(STATE_JOINED);
          break;
        case 'left-meeting':
          callObject.destroy().then(() => {
            setCallObject(null);
            setAppState(STATE_IDLE);
          });
          break;
        case 'error':
          setAppState(STATE_ERROR);
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    /*
     * Listen for changes in state.
     * We can't use the useDailyEvent hook (https://docs.daily.co/reference/daily-react/use-daily-event) for this
     * because right now, we're not inside a <DailyProvider/> (https://docs.daily.co/reference/daily-react/daily-provider)
     * context yet. We can't access the call object via daily-react just yet, but we will later in Call.js and HairCheck.js!
     */
    events.forEach((event) => callObject.on(event, handleNewMeetingState));

    // Stop listening for changes in state
    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
    };
  }, [callObject]);

  const meetingPeriod = () => {
    const startsAt = new Date(session && session.startsAt);
    const endsAt = new Date(session && session.endsAt);

    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const startTime = new Intl.DateTimeFormat('en-US', options).format(
      startsAt,
    );
    const endTime = new Intl.DateTimeFormat('en-US', options).format(endsAt);

    const formattedTime = `${startTime} - ${endTime}`;

    return formattedTime;
  };


  return (
    <div>
      <div className="min-h-screen bg-[#eddbff] flex flex-col lg:flex-row">
        <div className="w-full lg:w-[73%]">
          {callObject && (
            <CallInterface
              callObject={callObject}
              startLeavingCall={startLeavingCall}
            />
          )}
        </div>
        <div className=" lg:w-[27%] py-8 px-4 gap-4 flex flex-col sm:flex-row lg:flex-col items-stretch">
          <div className="w-full sm:w-[50%] lg:w-full rounded-[16px] bg-white p-4">
            <h2 className="font-bold text-sm mb-4">
              {session ? session.title : ''}
            </h2>
            <p className="text-sm">45 mins session</p>
            <p className="text-sm">{session && meetingPeriod()}</p>
            <div className="flex mt-4 items-center">
              <img
                src={session ? session.participant.profilePhoto : ''}
                alt=""
                className="rounded-full mr-4 bg-[grey] w-[60px] h-[60px]"
              />
              <div>
                <p className="text-sm">
                  {session
                    ? `${session.participant.firstName} ${session.participant.lastName}`
                    : ''}
                </p>
                <p className="text-sm">
                  {session ? session.participant.jobTitle : ''}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-[50%] lg:w-full rounded-[16px] bg-white p-4">
            <h2 className="font-bold mb-4 text-sm">Call Agenda</h2>
            <p className="text-sm">{session ? session.agenda : ''}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meeting;
