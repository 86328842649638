import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import {getEnvVars} from '../../app/env'

const initialState = {
  sessions: [],
  sessionsPagination: {},
  session: null,
  savedSession: null,
  giftSessions: null,
  savedGiftedSession: null,
  requestStatus: 'idle',
  callsStatus: 'idle',
  updateStatus: 'idle',
  paymentStatus: 'idle',
  giftStatus: 'idle',
  claimedGiftStatus: 'idle',
  refundStatus: 'idle',
  error: null,
  paymentData: null,
  price: '',
};

const {REACT_APP_API_URL: URL, REACT_APP_FRONTEND_URL} = getEnvVars()

export const createSession = createAsyncThunk(
  'book/session',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/sessions`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken'),
      },
    };
    try {
      const res = await axios.post(
        api,
        {
          startsAt: payload.startsAt,
          endsAt: payload.endsAt,
          expertId: payload.expertId,
          title: payload.title,
          agenda: payload.agenda,
          isScheduled: payload.isScheduled,
          sessionUrl: `${REACT_APP_FRONTEND_URL}/expert/call/detail/_sessionId_`,
        },
        configReq
      );
      if (res.status && res.status === 201) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.error(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const sessionPayment = createAsyncThunk(
  'session/payment',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/sessions/payments`;
    const clientConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };
    try {
      const res = await axios.post(
        api,
        {
          sessionId: payload.sessionId,
          provider: payload.provider,
          transactionId: payload.transactionId.toString(),
          sessionUrl: `${REACT_APP_FRONTEND_URL}/expert/call/detail/${payload.sessionId}`,
        },
        clientConfig
      );
      if (res.status && res.status === 201) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  }
);

export const sessionRefund = createAsyncThunk(
  'session/refund',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/refund-requests`;

    const clientConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };

    try {
      const res = await axios.post(
        api,
        {
          sessionId: payload.sessionId,
        },
        clientConfig
      );

      if (res.status && res.status === 201) {
        return res;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      const errMessage = err.response?.data?.message || "Error requesting for session refund"
      toast.error(errMessage);
      return rejectWithValue(err.message);
    }
  }
);

export const claimGiftSession = createAsyncThunk(
  'claimiGft/session',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/gift-sessions`;
    const clientConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };
    try {
      const res = await axios.patch(
        api,
        {
          startsAt: payload.startsAt,
          giftSessionId: payload.giftSessionId,
          title: payload.title,
          viewSessionUrl: `${REACT_APP_FRONTEND_URL}/call/detail/_sessionId_`,
          agenda: payload.agenda,
        },
        clientConfig
      );
      if (res?.status < 400) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const getGiftSession = createAsyncThunk(
  'get/gifts',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/gift-sessions/me`;
    const token = payload.admin
      ? localStorage.getItem('exptToken')
      : localStorage.getItem('userToken');

    const configReq = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const res = await axios.get(api, configReq);
      if (res.status && res.status === 200) {
        return res.data.data.docs;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  }
);

export const giftSession = createAsyncThunk(
  'gift/payment',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/gift-sessions`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken'),
      },
    };
    try {
      const res = await axios.post(
        api,
        {
          // sessionId: payload.sessionId,
          provider: payload.provider,
          transactionId: payload.transactionId,
          recipientName: payload.recipientName,
          recipientEmail: payload.recipientEmail,
          expertId: payload.expertId,
          homepageUrl: REACT_APP_FRONTEND_URL,
        },
        configReq
      );
      if (res.status && res.status === 201) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  }
);

export const singleSession = createAsyncThunk(
  'single/session',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/sessions/${payload.id}`;
    const clientConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };
    const expertConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.exptToken,
      },
    };
    const currConfig = payload.admin ? expertConfig : clientConfig;
    try {
      const res = await axios.get(api, currConfig);
      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  }
);

export const getSession = createAsyncThunk(
  'get/sessions',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/sessions/me?orderBy=-createdAt&skip=${
      payload.skip ? payload.skip : 0
    }&limit=10`;
    const clientConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };
    const expertConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.exptToken,
      },
    };
    const currConfig = payload.admin ? expertConfig : clientConfig;
    try {
      const res = await axios.get(api, currConfig);
      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      localStorage.removeItem('exptToken');
      localStorage.removeItem('userToken');
      window.location.reload();
      return rejectWithValue(err.message);
    }
  }
);

export const updateSession = createAsyncThunk(
  'update/sessions',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/sessions/${payload.id}/status`;
    const clientConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };
    const expertConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.exptToken,
      },
    };
    const currConfig = payload.admin ? expertConfig : clientConfig;
    const toastID = Math.random();
    try {
      const res = await axios.patch(
        api,
        {
          status: payload.status,
          paymentUrl: `${REACT_APP_FRONTEND_URL}/book/_sessionId_/payment`,
          searchExpertsUrl: `${REACT_APP_FRONTEND_URL}/search`,
        },
        currConfig
      );
      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message, { toastId: toastID });
      return rejectWithValue(err.response.data);
    }
  }
);

export const sessionPrice = createAsyncThunk(
  'covert/price',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/payments/flutterwave/currency-conversion?currency=${payload.currency}&amount=${payload.amount}`;
    const clientConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };
    try {
      const res = await axios.get(api, clientConfig);

      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    saveSession: (state, action) => {
      state.savedSession = action.payload;
    },
    resetSession: (state) => {
      state.requestStatus = 'idle';
      state.refundStatus = 'idle';
      state.updateStatus = 'idle';
      state.claimedGiftStatus = 'idle';
      state.sessions = [];
      state.session = null;
    },
    getGiftedSession: (state, action) => {
      state.savedGiftedSession = action.payload;
    },
    clearSession: (state) => {
      state.sessions = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(singleSession.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(singleSession.fulfilled, (state, action) => {
        state.requestStatus = 'success';
        state.session = action.payload;
      })
      .addCase(singleSession.rejected, (state, action) => {
        state.requestStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(createSession.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(createSession.fulfilled, (state, action) => {
        state.requestStatus = 'success';
        state.session = action.payload;
      })
      .addCase(createSession.rejected, (state, action) => {
        state.requestStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(getSession.pending, (state) => {
        state.callsStatus = 'loading';
      })
      .addCase(getSession.fulfilled, (state, action) => {
        state.callsStatus = 'success';
        state.sessions = [...state.sessions, ...action.payload.docs];
        state.sessionsPagination = action.payload
      })
      .addCase(getSession.rejected, (state, action) => {
        state.callsStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(sessionPayment.pending, (state) => {
        state.paymentStatus = 'loading';
      })
      .addCase(sessionPayment.fulfilled, (state, action) => {
        state.paymentStatus = 'success';
        state.paymentData = action.payload;
      })
      .addCase(sessionPayment.rejected, (state, action) => {
        state.paymentStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(updateSession.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateSession.fulfilled, (state, action) => {
        state.updateStatus = 'success';
        state.session = action.payload;
      })
      .addCase(updateSession.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(giftSession.pending, (state) => {
        state.paymentStatus = 'loading';
      })
      .addCase(giftSession.fulfilled, (state, action) => {
        state.paymentStatus = 'success';
        state.session = action.payload;
      })
      .addCase(giftSession.rejected, (state, action) => {
        state.paymentStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(getGiftSession.pending, (state) => {
        state.giftStatus = 'loading';
      })
      .addCase(getGiftSession.fulfilled, (state, action) => {
        state.giftStatus = 'success';
        state.giftSessions = action.payload;
      })
      .addCase(getGiftSession.rejected, (state, action) => {
        state.giftStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(sessionRefund.pending, (state) => {
        state.refundStatus = 'loading';
      })
      .addCase(sessionRefund.fulfilled, (state) => {
        state.refundStatus = 'success';
      })
      .addCase(sessionRefund.rejected, (state) => {
        state.refundStatus = 'failed';
      })
      .addCase(claimGiftSession.pending, (state) => {
        state.claimedGiftStatus = 'loading';
      })
      .addCase(claimGiftSession.fulfilled, (state) => {
        state.claimedGiftStatus = 'success';
      })
      .addCase(claimGiftSession.rejected, (state, action) => {
        state.claimedGiftStatus = 'failed';
        state.claimedGiftStatus = action.payload;
      })
      .addCase(sessionPrice.fulfilled, (state, action) => {
        state.price = action.payload;
      });
  },
});

export const { saveSession, resetSession, getGiftedSession, clearSession } =
  sessionSlice.actions;

export default sessionSlice.reducer;
