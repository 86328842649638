import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/layout';
import Header from '../../components/banner';
import { BsCalendar3, BsClock } from 'react-icons/bs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import confirm from '../../assets/icons/confirm.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSession,
  singleSession,
  updateSession,
} from '../../features/session/sessionSlice';
import RequestedCall from './RequestedCall';
import ReactLoading from 'react-loading';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { FiUser } from 'react-icons/fi';

const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const ExptCallDetail = () => {
  const dispatch = useDispatch();
  const { session, updateStatus, requestStatus } = useSelector(
    (state) => state.session,
  );
  const navigate = useNavigate();
  const { callId } = useParams();
  const location = useLocation();
  const [modal, setModal] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [approveState, setApproveState] = useState(false);

  useEffect(() => {
    const currTime = new Date().getTime();
    setStartTime(new Date(currTime + 1200000).getTime());
  }, []);

  useEffect(() => {
    dispatch(
      singleSession({
        id: callId,
        admin: location.pathname.includes('expert') ? true : false,
      }),
    );
    return () => {
      dispatch(resetSession());
    };
  }, []);

  useEffect(() => {
    if (updateStatus === 'success' && !approveState) {
      setModal(4);
    } else if (updateStatus === 'success') {
      setModal(5);
    }
    return () => {
      dispatch(resetSession());
    };
  }, [updateStatus]);

  const handleCall = () => {
    if (
      startTime > new Date(session?.startsAt).getTime() &&
      new Date().getTime() <= new Date(session?.endsAt).getTime()
    ) {
      navigate(`/meeting?roomUrl=${session?.url}`);
    }
    return;
  };

  const handleApproveCall = () => {
    setApproveState(true);
    dispatch(
      updateSession({
        id: callId,
        admin: location.pathname.includes('expert') ? true : false,
        status: 'accepted',
      }),
    );
  };

  const handleDeclineCall = () => {
    setApproveState(false);
    setModal(5);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    dispatch(
      updateSession({
        id: callId,
        status: 'declined',
        admin: location.pathname.includes('expert') ? true : false,
      }),
    );
  };

  const getStatus = (session) => {
    const sessionIsBookedAndPaid =
      (session?.isScheduled &&
        session?.status === 'accepted' &&
        session?.url) ||
      !session?.isScheduled;

    if (session?.status === 'completed') {
      return 'Done';
    }
    if (session?.status === 'accepted' && !session?.paymentId) {
      return 'Unpaid';
    }
    if (session?.status === 'cancelled') {
      return 'Cancelled';
    }
    if (sessionIsBookedAndPaid) {
      return 'Booked';
    }
    if (session?.isScheduled && session?.status === 'accepted') {
      return 'Accepted';
    }
    if (session?.isScheduled) {
      return 'Requested';
    }
    if (session?.status === 'declined') {
      return 'Declined';
    }

    return 'Booked';
  };

  const statusShed = (session) => {
    if (session?.status === 'completed') {
      return 'bg-gray-300';
    } else if (session?.status === 'accepted' && !session?.paymentId) {
      return 'text-blue-600 bg-blue-100';
    } else if (session?.isScheduled && session?.status === 'pending') {
      return 'bg-[#FFEFD2] text-[#BA8800]';
    } else if (
      (session?.isScheduled && session?.status === 'declined') ||
      session?.status === 'cancelled'
    ) {
      return 'bg-[#FFEDE9] text-[#BA1B1B]';
    } else {
      return 'text-green-600 bg-green-100';
    }
  };

  return (
    <ProtectedRoute>
      <div className="altNav">
        <Layout>
          <Header title="Calls" pathUrl="/expert/call" />
          {modal <= 4 ? (
            <div
              className={`grid mx-auto ${session?.isScheduled ? 'sm:px-20 px-4' : 'max-w-[33rem]'
                }`}
            >
              {modal === 0 && requestStatus === 'loading' ? (
                <div className="flex items-center justify-center align-middle mt-28">
                  <ReactLoading
                    type="spin"
                    color="#270058"
                    className=""
                    height={80}
                    width={80}
                  />
                </div>
              ) : modal === 0 && session ? (
                <div
                  className={` p-5 sm:p-8 bg-white rounded-2xl ${session?.isScheduled ? '' : 'max-w-[33rem]'
                    }`}
                >
                  <div className="sm:flex">
                    <span
                      className={`${statusShed(
                        session,
                      )} py-1.5 px-4 rounded-2xl mr-3`}
                    >
                      {getStatus(session)}
                    </span>

                    <p className="font-semibold leading-9 text-[28px] mt-3 sm:mt-0">
                      {session?.title}
                    </p>
                  </div>

                  <div className="my-8 grid gap-y-3">
                    <div className="flex gap-x-3">
                      <p className="text-neutral-500 flex font-medium">
                        <BsCalendar3 className="self-center mr-2" size={18} />{' '}
                        Date
                      </p>
                      <p>
                        {session &&
                          new Date(session?.startsAt).toLocaleDateString(
                            'en-US',
                            options,
                          )}
                      </p>
                    </div>
                    <div className="flex gap-x-3">
                      <p className="text-neutral-500 flex font-medium">
                        <BsClock className="self-center mr-2" size={18} /> Time
                      </p>
                      <p>
                        {session &&
                          new Date(session?.startsAt).toLocaleTimeString()}{' '}
                        -{' '}
                        {session &&
                          new Date(session?.endsAt).toLocaleTimeString()}
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-x-4">
                    <div className="sm:flex gap-x-4 items-center">
                      {session?.participant?.profilePhoto ? (
                        <div className="sm:w-14 sm:h-14 w-12 h-12 rounded-full object-cover">
                          <img
                            src={session?.participant?.profilePhoto}
                            alt=""
                            className="w-full h-full rounded-full"
                          />
                        </div>
                      ) : (
                        <div className=" bg-gray-100 w-14 h-14 rounded-full flex justify-center align-middle items-center">
                          <FiUser className="text-[#270058]" size={28} />
                        </div>
                      )}
                      <div>
                        <p>
                          {session &&
                            `${session?.participant?.firstName} ${session?.participant?.lastName}`}
                        </p>
                        <p className="text-neutral-500">
                          {session && session?.participant?.company}
                        </p>
                      </div>
                    </div>

                    <div className="">
                      {!session?.isScheduled && (
                        <button
                          className="float-right btnOut-red"
                          onClick={() => setModal(1)}
                        >
                          Cancel Call
                        </button>
                      )}
                    </div>
                  </div>

                  <hr className="h-0.5 border-none bg-neutral-200 my-10" />

                  {session?.isScheduled ? (
                    <div className="flex gap-2 align-middle text-xs sm:text-base items-center justify-between sm:justify-end">
                      <button
                        className={`bg-[#270058] ${session.status === 'accepted' ||
                            session.status === 'declined'
                            ? 'bg-[#6e6579]'
                            : 'bg-[#270058]'
                          } text-white py-3 rounded-md sm:px-14 px-8 flex items-center justify-center gap-2`}
                        onClick={handleApproveCall}
                      >
                        {updateStatus === 'loading' && (
                          <ReactLoading
                            type="cylon"
                            color="#fff"
                            className=""
                            height={20}
                            width={20}
                          />
                        )}
                        Approve Call
                      </button>
                      <button
                        className="border-bg-[#270058] sm:px-14 px-8 block border-2 py-3 rounded-md border-bd-[#270058]"
                        onClick={handleDeclineCall}
                      >
                        Decline Call
                      </button>
                    </div>
                  ) : (
                    <button
                      className={`w-full py-3 px-7 rounded-md shadow ${startTime > new Date(session?.startsAt).getTime() &&
                          new Date().getTime() <=
                          new Date(session?.endsAt).getTime()
                          ? 'bg-textPurple text-white'
                          : 'bg-gray-300'
                        }`}
                      onClick={handleCall}
                    >
                      Join Call
                    </button>
                  )}
                </div>
              ) : modal === 1 ? (
                <div className="card rounded-2xl mx-auto">
                  <form action="" className="px-8 py-12">
                    <h1 className="font-semibold text-[28px] text-center px-12 pb-8">
                      Are you sure you want to cancel your call?
                    </h1>
                    <label htmlFor="text">Reason</label>
                    <textarea
                      name=""
                      id=""
                      rows="3"
                      className="rounded-lg p-2 border border-neutral-200 w-full"
                      placeholder="State your reason for cancelling"
                    ></textarea>
                    <div className="pt-6 grid grid-cols-2 gap-x-10">
                      <button
                        className="btn-default w-full flex items-center gap-2 justify-center"
                        onClick={handleCancel}
                      >
                        {updateStatus === 'loading' && (
                          <ReactLoading
                            type="cylon"
                            color="#fff"
                            className=""
                            height={20}
                            width={20}
                          />
                        )}
                        Yes
                      </button>
                      <button
                        className="btn-default w-full !bg-[#EDDBFF] !text-[#270058]"
                        onClick={() => setModal(0)}
                      >
                        No
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="confirmation mx-auto card w-min md:w-max grid">
                  <div className="text-center self-center mx-8">
                    <img src={confirm} className="mx-auto" alt="confirm-icon" />
                    <h1 className="font-bold text-3xl leading-9 my-8 w-3/4 mx-auto">
                      Your call was successfully canceled.
                    </h1>
                    <Link to="/expert/call">
                      <button className="mt-8 mb-4 font-medium bg-[#00164F] text-white rounded-lg py-3 px-5">
                        Done
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <RequestedCall
              approveState={approveState}
              handleClose={() => setModal(0)}
            />
          )}
        </Layout>
      </div>
    </ProtectedRoute>
  );
};

export default ExptCallDetail;
