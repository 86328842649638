import React from 'react'

const ExpertLoadingCard = () => {
    return (
        <div>
            <div className="grid rounded-2xl shadow-sm px-4 py-6 bg-white text-neutral-400 mr-3 h-max md:h-72">
                <div
                    role="status"
                    className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
                >
                    <div className="h-5 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
                    <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
                    <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg" />
                    <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
                </div>
            </div>
        </div>
    )
}

export default ExpertLoadingCard