import React, { useEffect, useState } from 'react';
import Layout from '../components/layout/layout';
import Header from '../components/banner';
import { BsCalendar3, BsClock } from 'react-icons/bs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import confirm from '../assets/icons/confirm.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSession,
  resetSession,
  singleSession,
  updateSession,
} from '../features/session/sessionSlice';
import ReactLoading from 'react-loading';
import ProtectedRoute from '../utils/ProtectedRoute';
import { FiUser } from 'react-icons/fi';

export const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const CallDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { callId } = useParams();
  localStorage.setItem('callId', callId);
  const { session, updateStatus, requestStatus } = useSelector(
    (state) => state.session,
  );
  const { profile } = useSelector(
    (state) => state.user,
  );
  const [modal, setModal] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const currTime = new Date().getTime();
    setStartTime(new Date(currTime + 1200000).getTime());
  }, []);

  useEffect(() => {
    dispatch(
      singleSession({
        id: callId,
        admin: location.pathname.includes('expert') ? true : false,
      }),
    );
    return () => {
      dispatch(resetSession());
    };
  }, []);

  useEffect(() => {
    if (updateStatus === 'success') {
      setModal(2);
    }
    return () => {
      if (updateStatus === 'success') {
        dispatch(resetSession());
      }
    };
  }, [updateStatus]);

  const handleCall = () => {
    if (
      startTime > new Date(session?.startsAt).getTime() &&
      new Date().getTime() <= new Date(session?.endsAt).getTime()
    ) {
      // window.open(session?.url, '_blank');
      navigate(`/meeting?roomUrl=${session?.url}`);
    }
    return;
  };

  const handleCancel = (e) => {
    e.preventDefault();
    dispatch(clearSession());
    dispatch(
      updateSession({
        id: callId,
        status: 'cancelled',
        admin: location.pathname.includes('expert') ? true : false,
      }),
    );
  };

  const getStatus = (session) => {
    switch (session?.status) {
      case 'upcoming':
      case 'completed':
      case 'unpaid':
      case 'cancelled':
      case 'holding':
        return session.status.charAt(0).toUpperCase() + session.status.slice(1);

      case 'declined':
        return 'Declined';

      default:
        if (session?.status?.includes('missed')) return 'Missed';
        if (session?.isScheduled && session?.status === 'pending') return 'Requested';
        return 'Pending';
    }
  };

  const statusShed = (session) => {
    const { status, isScheduled } = session || {};

    if (status === 'completed') {
      return 'bg-[#EDEEF2]';
    }

    if (status === 'unpaid') {
      return 'text-[#01655F] bg-[#F0FFFE]';
    }

    if (status === 'holding') {
      return 'text-[#5A73C4] bg-[#DAE1FF]';
    }

    if (isScheduled && status === 'pending') {
      return 'bg-[#FFEFD2] text-[#BA8800]';
    }

    if (
      (isScheduled && status === 'declined') ||
      status === 'cancelled' ||
      status?.includes('missed')
    ) {
      return 'bg-[#FFEDE9] text-[#BA1B1B]';
    }

    return 'text-green-600 bg-green-100';
  };

  return (
    <ProtectedRoute>
      <div className="altNav">
        <Layout>
          <Header title="Calls" pathUrl={'/call'} />
          <div className="grid mx-auto px-4 sm:w-max">
            {modal === 0 && requestStatus === 'loading' ? (
              <div className="flex items-center justify-center align-middle mt-28">
                <ReactLoading
                  type="spin"
                  color="#270058"
                  className=""
                  height={80}
                  width={80}
                />
              </div>
            ) : modal === 0 && session ? (
              <div className="rounded-2xl sm:min-w-[31rem] bg-white sm:p-8 p-5">
                <div className="sm:flex">
                  <span
                    className={`${statusShed(
                      session,
                    )} sm:py-1.5 py-1 sm:px-4 px-3 rounded-2xl mr-3 text-xs sm:text-base`}
                  >
                    {getStatus(session)}
                  </span>
                  <p className="font-semibold leading-9 sm:text-[28px] text-xl mt-3 sm:mt-0">
                    {session?.title}
                  </p>
                </div>

                <div className="sm:my-8 my-4 grid gap-y-3">
                  <div className="flex gap-x-3 text-sm sm:text-base">
                    <p className="text-neutral-500 flex font-medium">
                      <BsCalendar3 className="self-center mr-2" size={18} />{' '}
                      Date
                    </p>

                    <p>
                      {session &&
                        new Date(session?.startsAt).toLocaleDateString(
                          'en-US',
                          options,
                        )}
                    </p>
                  </div>

                  <div className="flex gap-x-3 text-sm sm:text-base">
                    <p className="text-neutral-500 flex font-medium">
                      <BsClock className="self-center mr-2" size={18} /> Time
                    </p>
                    <p>
                      {session &&
                        new Date(session?.startsAt).toLocaleTimeString()}{' '}
                      -{' '}
                      {session &&
                        new Date(session?.endsAt).toLocaleTimeString()}
                    </p>
                  </div>
                </div>

                <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-4">
                  <div className="flex gap-x-4 items-center">
                    {session?.participant?.profilePhoto ? (
                      <div className="sm:w-14 sm:h-14 w-12 h-12 rounded-full object-cover">
                        <img
                          src={session?.participant.profilePhoto}
                          alt=""
                          className="w-full h-full rounded-full"
                        />
                      </div>
                    ) : (
                      <div className=" bg-gray-100 w-14 h-14 rounded-full flex justify-center align-middle items-center">
                        <FiUser className="text-[#270058]" size={28} />
                      </div>
                    )}

                    <div>
                      <p>
                        {session &&
                          `${session?.participant?.firstName} ${session?.participant?.lastName}`}
                      </p>

                      <p className="text-neutral-500">
                        {session && session?.participant?.company}
                      </p>
                    </div>
                  </div>

                  {
                    session?.status === 'pending' || session?.status === 'upcoming' && (
                      <div className="mt-4 sm:mt-0">
                        <button
                          className="sm:float-right btnOut-red text-sm sm:text-base"
                          onClick={() => setModal(1)}
                        >
                          Cancel Call
                        </button>
                      </div>
                    )
                  }
                </div>

                <hr className="h-0.5 border-none bg-neutral-200 sm:my-10 my-3" />

                {
                  profile?.role === "client" && session?.status === "missed+reschedule" ? (
                    <button
                      className={`w-full py-3 px-7 rounded-md shadow cursor-pointer text-sm sm:text-base bg-textPurple text-white`}
                      onClick={() => navigate(`/book/${session?.participant.id}`, { state: { sessionId: session?.id } })}
                    >
                      Reschedule Call
                    </button>
                  ) : (
                    <button
                      className={`w-full py-3 px-7 rounded-md shadow cursor-pointer text-sm sm:text-base ${startTime > new Date(session?.startsAt).getTime() &&
                        new Date().getTime() <= new Date(session?.endsAt).getTime()
                        ? 'bg-textPurple text-white'
                        : 'bg-gray-300'
                        }`}
                      onClick={handleCall}
                    >
                      Join Call
                    </button>
                  )
                }
              </div>
            ) : modal === 1 ? (
              <div className="card rounded-2xl w-9/12 mx-auto">
                <form action="" className="">
                  <h1 className="font-semibold text-[28px] text-center px-12 pb-8">
                    Are you sure you want to cancel your call ?
                  </h1>
                  <label htmlFor="text">Reason</label>
                  <textarea
                    name=""
                    id=""
                    rows="3"
                    className="rounded-lg p-2 border border-neutral-200 w-full"
                    placeholder="State your reason for cancelling"
                  ></textarea>
                  <div className="pt-6 grid grid-cols-2 gap-x-10">
                    <button
                      className="btn-default w-full flex items-center gap-2 justify-center"
                      onClick={handleCancel}
                    >
                      {updateStatus === 'loading' && (
                        <ReactLoading
                          type="cylon"
                          color="#fff"
                          className=""
                          height={20}
                          width={20}
                        />
                      )}
                      Yes
                    </button>
                    <button
                      className="btn-default w-full !bg-[#EDDBFF] !text-[#270058]"
                      onClick={() => setModal(0)}
                    >
                      No
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="confirmation mx-auto card w-min md:w-max grid">
                <div className="text-center self-center mx-8">
                  <img src={confirm} className="mx-auto" alt="confirm-icon" />
                  <h1 className="font-bold text-3xl leading-9 my-8 w-3/4 mx-auto">
                    Your call was successfully canceled.
                  </h1>
                  <Link to="/call">
                    <button className="mt-8 mb-4 font-medium bg-[#00164F] text-white rounded-lg py-3 px-5">
                      Done
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </Layout>
      </div>
    </ProtectedRoute>
  );
};

export default CallDetail;
