import React, { useEffect, useMemo, useState } from 'react';
import Layout from '../components/layout/layout';
import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import Header from '../components/banner';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSession,
  resetSession,
  getGiftSession,
} from '../features/session/sessionSlice';
import calendar from '../assets/icons/calendar.svg';
import { RxMagnifyingGlass } from 'react-icons/rx';
import ProtectedRoute from '../utils/ProtectedRoute';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactLoading from 'react-loading';
import { getEnvVars } from '../app/env';
import Container from '../components/shared/Container';
import SessionCard from '../components/SessionComponents/SessionCard';

const { REACT_APP_FF_AD_IMAGES } = getEnvVars();

const options = {
  day: 'numeric',
  year: 'numeric',
  month: 'long',
};

const duration = {
  hour: 'numeric',
  minute: 'numeric',
};

const Callpage = () => {
  const dispatch = useDispatch();
  const { sessions, callsStatus, giftSessions, sessionsPagination } =
    useSelector((state) => state.session);

  const navigate = useNavigate();
  const location = useLocation();
  const [userId /*setUserId*/] = useState(() => localStorage.userId);
  const filteredValue = useMemo(
    () =>
      giftSessions?.filter(
        (item) => !item?.isClaimed && item.recipient.id === userId,
      ),
    [giftSessions, userId],
  );

  const [skip, /*setSkip*/] = useState(0);
  const [paginationLoading, /*setPaginationLoading*/] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    dispatch(
      getSession({ admin: location.pathname.includes('expert'), skip: skip }),
    );

    return () => {
      dispatch(resetSession());
    };
  }, [skip]);

  useEffect(() => {
    if (!location.pathname.includes('expert') && !paginationLoading) {
      dispatch(
        getGiftSession({
          admin: location.pathname.includes('expert'),
        }),
      );
    }
    return () => {
      dispatch(resetSession());
    };
  }, []);

  useEffect(() => {
    const handleScrollButton = () => {
      window.scrollY > 300 ? setShowButton(true) : setShowButton(false);
    };

    window.addEventListener('scroll', handleScrollButton);

    return () => {
      window.removeEventListener('scroll', handleScrollButton);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getStatus = (session) => {
    switch (session?.status) {
      case 'upcoming':
      case 'completed':
      case 'unpaid':
      case 'cancelled':
      case 'holding':
        return session.status.charAt(0).toUpperCase() + session.status.slice(1);

      case 'declined':
        return 'Declined';

      default:
        if (session?.status?.includes('missed')) return 'Missed';
        if (session?.isScheduled && session?.status === 'pending') return 'Requested';
        return 'Pending';
    }
  };

  const statusShed = (session) => {
    const { status, isScheduled } = session || {};

    if (status === 'completed') {
      return 'bg-[#EDEEF2]';
    }

    if (status === 'unpaid') {
      return 'text-[#01655F] bg-[#F0FFFE]';
    }

    if (status === 'holding') {
      return 'text-[#5A73C4] bg-[#DAE1FF]';
    }

    if (isScheduled && status === 'pending') {
      return 'bg-[#FFEFD2] text-[#BA8800]';
    }

    if (
      (isScheduled && status === 'declined') ||
      status === 'cancelled' ||
      status?.includes('missed')
    ) {
      return 'bg-[#FFEDE9] text-[#BA1B1B]';
    }

    return 'text-green-600 bg-green-100';
  };

  const handleFetchData = () => {
    // setPaginationLoading(true);
    // setSkip(skip + 10);
  };

  const handleSessionRedirect = (callSession) => {
    if (callSession.status === 'completed') {
      return;
    } else if (callSession.status === 'unpaid') {
      navigate(`/book/${callSession.id}/payment`);
    } else {
      navigate(
        `${location.pathname.includes('expert')
          ? '/expert/call/detail/' + callSession?.id
          : '/call/detail/' + callSession?.id
        }`,
      );
    }
  };

  return (
    <ProtectedRoute>
      <div className="altNav">
        <Layout>
          <Header
            title="Calls"
            pathUrl={
              location?.pathname.includes('expert') ? '/expert/call' : '/call'
            }
          />
          <Container>
            <div className="grid gap-y-6 sm:pt-10">
              {sessions?.length !== 0 || filteredValue ? (
                <>
                  {sessions && (
                    <InfiniteScroll
                      dataLength={sessions?.length}
                      next={handleFetchData}
                      hasMore={sessionsPagination.totalDocs > sessions.length}
                      loader={
                        <div className=" flex align-middle items-center justify-center p-10">
                          <ReactLoading
                            type="spin"
                            color="black"
                            className=""
                            height={80}
                            width={80}
                          />
                        </div>
                      }
                      endMessage={<p>No more data to load.</p>}
                    >
                      <div className="flex flex-col gap-y-6">
                        {filteredValue &&
                          filteredValue.map((session, key) => (
                            <React.Fragment key={key}>
                              {filteredValue?.length !== 0 ? (
                                <SessionCard
                                  session={session}
                                  type="gifted"
                                  onSessionClick={(url) => navigate(url)}
                                  options={options}
                                />
                              ) : null}
                            </React.Fragment>
                          ))}
                        {sessions.map((session, key) => (
                          <SessionCard
                            session={session}
                            type="regular"
                            onSessionClick={handleSessionRedirect}
                            getStatus={getStatus}
                            statusShed={statusShed}
                            options={options}
                            duration={duration}
                            key={key}
                            location={location}
                          />
                        ))}
                      </div>
                    </InfiniteScroll>
                  )}
                </>
              ) : sessions?.length === 0 &&
                !giftSessions &&
                callsStatus === 'success' ? (
                <div className="text-center">
                  <img src={calendar} className="mx-auto" alt="calendar-icon" />

                  <div className="my-12">
                    <p className="text-xl font-semibold">
                      Looks like you don’t have any booked call
                    </p>

                    <p className="text-neutral-500">
                      It’s easy to book a call. You can start by searching for
                      an expert
                    </p>
                  </div>

                  {location.pathname.includes('expert') ? (
                    <button
                      className="flex btn-default mx-auto"
                      onClick={() => navigate('/expert/dashboard')}
                    >
                      <span>Dashboard</span>
                    </button>
                  ) : (
                    <button
                      className="flex btn-default mx-auto"
                      onClick={() => navigate('/search')}
                    >
                      <RxMagnifyingGlass
                        className="self-center mr-1.5"
                        size={25}
                      />
                      <span>Search for experts</span>
                    </button>
                  )}
                </div>
              ) : null}

              {callsStatus === 'loading' && !paginationLoading && (
                <>
                  <div className="grid rounded-2xl shadow-sm card">
                    <div
                      role="status"
                      className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
                    >
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
                    </div>
                  </div>
                  <div className="grid rounded-2xl shadow-sm card">
                    <div
                      role="status"
                      className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
                    >
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
                    </div>
                  </div>
                  <div className="grid rounded-2xl shadow-sm card">
                    <div
                      role="status"
                      className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
                    >
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
                    </div>
                  </div>
                </>
              )}

              {REACT_APP_FF_AD_IMAGES === 'true' && (
                <div className="bg-white flex items-center justify-center font-bold text-3xl min-h-[12rem] p-4 sm:px-6 py-8 rounded-lg mt-8 sm:rounded-2xl shadow-md">
                  ADS IMAGE
                </div>
              )}
            </div>
          </Container>
        </Layout>
      </div>

      {showButton && (
        <div
          className="fixed shadow-md sm:bottom-16 bottom-8 rounded-full sm:right-20 right-4 cursor-pointer"
          onClick={handleScrollToTop}
        >
          <BsFillArrowUpCircleFill className="text-black text-4xl" />
        </div>
      )}
    </ProtectedRoute>
  );
};

export default Callpage;
