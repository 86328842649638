import React from 'react'
import { getEnvVars } from '../../app/env';
import { FiUser } from 'react-icons/fi';
import { FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import star from '../../assets/icons/starfilled.svg';
import { Link } from 'react-router-dom';

const { REACT_APP_FF_AD_IMAGES } = getEnvVars();

const ExpertCard = ({ expert, handleGiftModal }) => {
    const { currency, rateInUserCurrency } =
        useSelector((state) => state.user);


    return (
        <div
            className="py-6 px-6 bg-white shadow-sm rounded-2xl"
            key={expert.id}
        >
            <div className="flex flex-col h-full">
                <div className="lg:flex">
                    {expert?.profilePhoto ? (
                        <div className="h-24 w-24 rounded-lg mr-2 object-cover relative overflow-hidden">
                            <img
                                src={expert?.profilePhoto}
                                alt=""
                                className="w-full h-full object-cover object-top"
                            />
                        </div>
                    ) : (
                        <div className="w-24 h-24">
                            <FiUser className="text-[#270058] m-auto" size={88} />
                        </div>
                    )}

                    <div className="flex justify-between w-full">
                        <div className="mr-28 2xl:mr-72">
                            <div className=" ">
                                <p className="text-[22px] font-bold">
                                    {(expert.firstName + ' ' + expert.lastName)
                                        .length > 12 ? (
                                        (
                                            expert.firstName +
                                            ' ' +
                                            expert.lastName
                                        ).slice(0, 12) + '...'
                                    ) : (
                                        <>{expert.firstName + ' ' + expert.lastName}</>
                                    )}
                                </p>
                            </div>
                            <p className="text-sm text-gray-500">
                                {expert.jobTitle?.length > 22
                                    ? expert.jobTitle?.slice(0, 22) + '...'
                                    : expert.jobTitle}
                            </p>
                            <div className="flex">
                                <img src={star} alt="rating-icon" className="w-5" />
                                <span className="text-[22px] font-bold pt-1">
                                    {expert.rating}
                                </span>
                            </div>
                        </div>
                        <div className="self-start lg:text-right">
                            <p className="text-gray-500">From</p>
                            <p className="font-bold">
                                {rateInUserCurrency == null
                                    ? null
                                    : new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency,
                                    }).format(expert?.fee * rateInUserCurrency)}
                            </p>
                        </div>
                    </div>
                </div>

                <p className="text-gray-500 py-4">
                    {expert?.bio?.length > 100 ? (
                        expert?.bio.slice(0, 100) + '...'
                    ) : (
                        <>{expert?.bio}</>
                    )}
                </p>

                {REACT_APP_FF_AD_IMAGES === 'true' && (
                    <div className="w-full mb-4 flex gap-3 items-center">
                        <button className="!bg-textPurple/10 text-textPurple rounded-lg flex gap-2 items-center justify-between px-2 py-0.5 text-sm">
                            <FaLinkedinIn className="text-textPurple text-sm" />
                            LinkedIn
                        </button>

                        <button className="!bg-textPurple/10 text-textPurple rounded-lg flex gap-2 items-center justify-between px-2 py-0.5 text-sm">
                            <FaTwitter className="text-textPurple text-sm" />
                            Twitter
                        </button>
                    </div>
                )}

                <div className="flex flex-wrap gap-2 lg:gap-4">
                    {expert?.skills.map((item, idx) => (
                        <span
                            className="bg-gray-100 rounded-lg text-xs md:text-sm w-max px-2 py-1"
                            key={item + idx}
                        >
                            {item}
                        </span>
                    ))}
                </div>

                <div className="pt-6 grid grid-cols-2 gap-4 gap-expert-10 mt-auto">
                    <Link to={'/book/' + expert.id}>
                        <button className="btn-default w-full">
                            Book call
                        </button>
                    </Link>
                    <button
                        className="btn-default w-full !bg-[#EDDBFF] !text-[#270058]"
                        onClick={handleGiftModal}
                    >
                        Gift call
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ExpertCard