import React from 'react'
import { Link } from 'react-router-dom'
import confirm from '../../assets/icons/confirm.svg';

const SuccessModal = ({ ctaText, to, paragraph, heading, className }) => {
    return (
        <div className={`mx-auto card max-w-[696px] grid text-center self-center px-10 ${className.container}`}>
            <img src={confirm} className="mx-auto" alt="confirm-icon" />

            <h1 className="font-bold text-2xl leading-9 mt-8 mx-auto">
                {heading}
            </h1>

            <p className='max-w-[493px] my-2'>
                {paragraph}
            </p>

            <Link to={to}>
                <button className="mt-8 mb-4 font-medium bg-[#00164F] text-white rounded-lg py-3 px-10">
                    {ctaText}
                </button>
            </Link>
        </div>
    )
}

export default SuccessModal